<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">수행도평가 관리</h2>
      </div>
      <div class="body_section">
        <Tabs
          :keyParam="KEY_PARAM"
          :dataList="TAB_DATA_LIST"
          :currentValue.sync="currentTabValue"
          @onChangeTabValue="onChangeTabValue"
        />
        <!-- 
        <PerformanceFilterBarResult
          v-if="currentTabValue === 'result'"
          :defaultRadioId="defaultRadioId"
          :defaultInputText="defaultInputText" />
        <PerformanceFilterBarState
          v-if="currentTabValue === 'present'"
          :defaultStatus="defaultStatus"
          :defaultRadioId="defaultRadioId"  />
        <TableHead>
          <template v-slot:headRight>
            
          </template>
        </TableHead> -->
        <PerformanceListResult v-if="currentTabValue === 'result'" />
        <PerformanceListState v-if="currentTabValue === 'present'" />
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import Tabs from "@/components/common/tabs/Tabs";
import PerformanceFilterBarState from "@/components/admin/performance/list/PerformanceFilterBarState";
import PerformanceFilterBarResult from "@/components/admin/performance/list/PerformanceFilterBarResult";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import PerformanceListState from "@/components/admin/performance/list/PerformanceListState";
import PerformanceListResult from "@/components/admin/performance/list/PerformanceListResult";
//import { GET_EVALUATE_PROGRESS_LIST } from '@/store/modules/performance/action'

import { mapState } from "vuex";
import { board } from "@/constants/options";
import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";

export default {
  name: "PerformanceList",
  components: {
    PageWithLayout,
    Tabs,
    PerformanceFilterBarState,
    PerformanceFilterBarResult,
    TableHead,
    SelectBoxPageRange,
    PerformanceListState,
    PerformanceListResult,
  },
  mixins: [PageMixin],
  data() {
    return {
      KEY_PARAM: "tab",
      TAB_DATA_LIST: [
        { value: "present", text: "평가현황" },
        { value: "result", text: "평가결과" },
      ],
      currentTabValue: "present", // 임시 테스트. 원래는 present가 디폴트 값. 테스트를 위해 result로 변경.
    };
  },
  // computed: {
  //   ...mapState({
  //     statusList: state => state.evaluate.statusList,
  //   }),
  // },
  beforeMount() {
    // this.$store.dispatch(GET_EVALUATE_PROGRESS_LIST).then(() => {
    // });
  },
  mounted() {},
  methods: {
    onChangeTabValue(value) {
      let query = { page: 1, tab: value };
      this.$router.replace({ query: query });
    },
  },
};
</script>
